/* eslint-disable eqeqeq */

import * as batches from '../../utils/batches'

import { AVAILABLE_ENTITIES, Repository, utils } from '../../database'
import { Container, TextField } from '@material-ui/core'
import { FAZENDA, RETIRO, formatName } from './../../utils/formHelper'
import React, { Component } from 'react'
import ResponsiveTable, { CELL_TYPES } from '../../components/ResponsiveTable'
import {
  currentDayIsD0,
  currentDayIsD0IATF,
  currentDayIsD0Ressinc,
  currentDayIsDG,
  currentDayIsDGD0,
  currentDayIsDGDN,
  currentDayIsDGFinal,
  currentDayIsDN,
  currentDayIsIatf,
  currentDayIsRessinc,
  managementsOver,
} from '../../utils/days'
import { endLoading, startLoading } from '../../redux/screen/actions'

import AddIcon from '@material-ui/icons/Add'
import { Autocomplete } from '@material-ui/lab'
import CallMissedOutgoingRoundedIcon from '@material-ui/icons/CallMissedOutgoingOutlined'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import MainContainer from '../../components/MainContainer'
import MobileCard from '../../components/MobileCard'
import Prompt from '../../components/Prompt'
import { Q } from '@nozbe/watermelondb'
import SyncIcon from '@material-ui/icons/Sync'
import SwapHorizontalCircleOutlinedIcon from '@material-ui/icons/SwapHorizontalCircleOutlined'
import TitleBar from '../../components/TitleBar'
import TopBar from '../../components/TopBar'
import TrainingsButton from '../Trainings/TrainingsButton'
import _ from 'lodash'
import { connect } from 'react-redux'
import { getFarmFile } from './../../redux/reports/actions'
import { getLoggedUser } from '../../redux/auth/actions'
import hasInternet from '../../utils/recognizeInternetConnection'
import { idConditionDGFinal } from '../../utils/idsHelper'
import moment from 'moment'
import { pullFarms } from '../../syncWorker'
import track from 'react-tracking'
import { verify } from '../../utils/verifyDuplicatePages'
import { withRouter } from 'react-router-dom'
import { LocalStorageHelper } from '../../utils/localStorageHelper'
import {
  ALL_FIELDS_TO_EXPORT,
  POWER_BI_FIELDS_TO_EXPORT,
} from '../../utils/constants'

@track(() => ({
  page: 'Listagem de Lotes/Retiros - Fazenda sem Retiro',
  date: new Date(),
}))
class Farm extends Component {
  constructor(props) {
    super(props)

    const hasCorral = props.farm.retiro
    const nameField = hasCorral ? 'nome' : 'nomeLote'

    const sortedData = hasCorral
      ? this.props.data.sort((a, b) => a.nome.localeCompare(b.nome))
      : this.props.data.sort((a, b) => a.nomeLote.localeCompare(b.nomeLote))

    this.state = {
      hasCorral,
      nameField,
      allData: sortedData,
      selectedData: sortedData,
      deleteConfirmationOpened: false,
      selectedBatch: null,
      selectedParentBatch: null,
      deleteCorralConfirmationOpened: false,
      selectedCorral: null,
      selectedCorralName: null,
      noConnection: false,
      vacas: [],
      cowCount: [],
      d0OutOfDay: false,
      blockingAdvice: null,
      showFields: false,
      allFieldsOptionsExport: ALL_FIELDS_TO_EXPORT,
      selectedFieldsOptionsExport:
        JSON.parse(LocalStorageHelper.get('ProGerarSavedFieldsToExport')) ?? [],
      currentBatch: null,
      backToPreviousRessincConfirmationOpened: false,
      erroModalOpened: false,
    }

    this.handleSearch = this.handleSearch.bind(this)
    this.deleteBatch = this.deleteBatch.bind(this)
    this.deleteCorral = this.deleteCorral.bind(this)

    if (hasCorral) {
      this.getCowsByCorralId()
    } else {
      this.getIatfDay()
      this.getCowsByBatchId()
    }
  }

  componentDidMount() {
    if (!!!LocalStorageHelper.get('ProGerarSavedFieldsToExport')) {
      LocalStorageHelper.add(
        'ProGerarSavedFieldsToExport',
        JSON.stringify(ALL_FIELDS_TO_EXPORT)
      )
    }
  }

  async getIatfDay() {
    const updatedSelectedBatches = this.state.selectedData.map(
      async (value, index) => {
        const protocol = await utils.getElement(
          AVAILABLE_ENTITIES.PROTOCOLS,
          this.props.batches[index].protocoloId
        )
        const managementIds = protocol?.managementProtocols.map(
          (manejo) => manejo.day
        )
        const daysManagement = await utils.getWithParam(
          AVAILABLE_ENTITIES.DOMAIN_VALUES,
          'id',
          Q.oneOf(managementIds || [])
        )
        const dayBatch =
          daysManagement.length > 0
            ? parseInt(
              daysManagement
                .sort(
                  (a, b) =>
                    parseInt(b.valor.replace(/\D/g, '')) -
                    parseInt(a.valor.replace(/\D/g, ''))
                )[0]
                .valor.replace(/\D/g, '')
            )
            : 0

        return {
          ...value,
          dataToIATF: moment
            .utc(
              this.props.batches[index].dataIatf._d.valueOf() +
              dayBatch * 24 * 3600 * 1000
            )
            .format('DD/MM/YYYY'),
        }
      }
    )
    const resolvedSelectedBatches = await Promise.all(
      updatedSelectedBatches
    ).then((values) => values)

    this.setState({ selectedData: resolvedSelectedBatches })
  }

  async getCowsByBatchId() {
    let vacas = await utils.getWithParam(
      AVAILABLE_ENTITIES.D0S,
      'batch_id',
      Q.oneOf(
        this.props.data.map((b) =>
          b.originalBatchId ? b.originalBatchId : b.id
        )
      )
    )

    this.setState({ vacas })
  }

  async getCowsByCorralId() {
    let batches = await utils.getWithParam(
      AVAILABLE_ENTITIES.BATCHES,
      'corral_id',
      Q.oneOf(this.state.selectedData.map((b) => b.id))
    )

    this.setState({ batches })

    let vacas = await utils.getWithParam(
      AVAILABLE_ENTITIES.D0S,
      'batch_id',
      Q.oneOf(this.state.batches.map((b) => b.id))
    )

    this.setState({ vacas })

    this.state.batches.map(
      (b) =>
      (b.vacas = this.state.vacas.filter(
        (v) => v.loteId === b.id && !b.originalBatchId && b.deleted === false
      ))
    )

    this.state.selectedData.map(
      (c) => (c.batches = this.state.batches.filter((b) => b.retiroId === c.id))
    )

    this.setState({
      cowCount: this.state.selectedData.map((c) => this.getCount(c)),
    })
  }

  checkBatchAccuracy(row) {
    let vacaPrenhe = this.props.condicaoDG.filter(
      (cdg) => cdg.valor === 'Prenhe'
    )[0]
    let batchCows = null
    let prenheCows = null

    if (row.originalBatchId != null) {
      batchCows = this.state.vacas.filter(
        (v) => v.loteId === row.originalBatchId
      )
      prenheCows = batchCows.filter((v) => v.condicaoDG === vacaPrenhe.id)
    } else {
      batchCows = this.state.vacas.filter((v) => v.loteId === row.id)
      prenheCows = batchCows.filter((v) => v.condicaoDG === vacaPrenhe.id)
    }

    if (prenheCows.length === batchCows.length) {
      return true
    } else {
      return false
    }
  }

  getCount(corral) {
    const batches = [corral.batches]
    let batch
    if (batches.length > 1) {
      for (let i = 0; i < batches.length - 1; i++) {
        batch = batches[i].concat(batches[i + 1])
      }
    } else {
      batch = batches[0]
    }

    const vacas = batch.map((b) => b.vacas.length)
    const count = {
      corralId: corral.id,
      countNumber: vacas.reduce((a, b) => a + b, 0),
    }

    return count
  }

  handleSearch(e) {
    const val = e.target.value
    if (_.isEmpty(val)) {
      this.setState({ selectedData: this.state.allData })
    } else {
      const filteredData = this.state.allData.filter(
        (x) =>
          x[this.state.nameField].toLowerCase().indexOf(val.toLowerCase()) >= 0
      )
      this.setState({ selectedData: filteredData })
    }
  }

  @track({ action: 'Clicou no botao de relatório' })
  async verifyConnection() {
    var isConnected = true //await verifyConnectionUser()
    if (isConnected) {
      this.props.history.push(`/farms/${this.props.farm.id}/report`)
    } else {
      alert(
        'Falha ao conectar. Esta funcionalidade não pode ser acessada no modo offline!'
      )
    }
  }

  @track((props, state, corral) => ({
    action: 'Deletou um Retiro',
    value: corral[0],
  }))
  async deleteCorral(selectedCorral) {
    if (selectedCorral === null) return

    const repository = new Repository(AVAILABLE_ENTITIES.CORRALS)
    const request = await repository.delete(selectedCorral)

    const newState = {
      deleteCorralConfirmationOpened: false,
      selectedCorral: null,
      selectedCorralName: null,
    }

    if (request.success) {
      console.log(`Delete of cow ${this.state.selecteCorral} successful`)
      newState.allData = this.state.allData.filter(
        (x) => x.id !== selectedCorral
      )
      newState.selectedData = this.state.allData.filter(
        (x) => x.id !== selectedCorral
      )
    } else {
      console.log(
        `Delete of cow ${this.state.selecteCorral} failed: ${request.exception}`
      )
    }

    this.setState(newState)
  }

  @track((props, state, batch) => ({
    action: 'Deletou um lote',
    value: batch[0],
  }))
  async deleteBatch(selectedBatch) {
    if (selectedBatch === null) return

    const repository = new Repository(AVAILABLE_ENTITIES.BATCHES)

    const request = await repository.delete(selectedBatch)

    const newState = {
      deleteConfirmationOpened: false,
      selectedBatch: null,
      selectedBatchName: null,
    }

    if (request.success) {
      console.log(`Delete of cow ${selectedBatch} successful`)
      newState.allData = this.state.allData.filter(
        (x) => x.id !== selectedBatch
      )
      newState.selectedData = this.state.allData.filter(
        (x) => x.id !== selectedBatch
      )
    } else {
      console.log(`Delete of cow ${selectedBatch} failed: ${request.exception}`)
    }

    var currentParentBatch = this.state.selectedParentBatch
    while (currentParentBatch != null || currentParentBatch != undefined) {
      var getNetxtParentBatch = await repository.getById(currentParentBatch)
      const request = await repository.delete(currentParentBatch)
      if (request.success) {
        currentParentBatch = getNetxtParentBatch.response.parentBatchId
      } else {
        console.log(
          `Delete of cow ${selectedBatch} failed: ${request.exception}`
        )
      }
    }

    this.setState(newState)
  }

  getPrenhez() {
    const allCows = [
      ...new Map(
        this.state.vacas
          .filter((v) => v.omit_from_dg_final === false)
          .concat(
            this.props.allVacas.filter((v) => v.omit_from_dg_final === false)
          )
          .map((item) => [item['id'], item])
      ).values(),
    ]

    const percentageToPrenhe = this.state.selectedData.map((item) => {
      if (item.originalBatchId) {
        const total = allCows.filter(
          (v) =>
            (v.loteId === item.originalBatchId || v.loteId === item.id) &&
            v.condicaoDGFinal !== null
        ).length
        const prenhes = allCows
          .filter(
            (v) =>
              (v.loteId === item.originalBatchId || v.loteId === item.id) &&
              v.condicaoDGFinal !== null
          )
          .filter(
            (i) =>
              i.condicaoDGFinal === idConditionDGFinal.pregnantIATF ||
              i.condicaoDGFinal === idConditionDGFinal.pregnantBull
          ).length

        return Math.floor((prenhes / total) * 100 * 100) / 100 || 0
      } else {
        const total = allCows.filter(
          (v) => v.loteId === item.id && v.condicaoDGFinal !== null
        ).length
        const prenhes = allCows
          .filter((v) => v.loteId === item.id && v.condicaoDGFinal !== null)
          .filter(
            (i) =>
              i.condicaoDGFinal === idConditionDGFinal.pregnantIATF ||
              i.condicaoDGFinal === idConditionDGFinal.pregnantBull
          ).length

        return Math.floor((prenhes / total) * 100 * 100) / 100 || 0
      }
    })

    return percentageToPrenhe
  }

  async fetchBatches(parentBatchId, batchRepository) {
    let batches = [];
    let currentParentBatchId = parentBatchId;

    while (currentParentBatchId) {
      const { response } = await batchRepository.getByParam('id', currentParentBatchId);

      if (response && response.length > 0) {
        batches.push(response[0]);
        currentParentBatchId = response[0].parentBatchId;
      } else {
        currentParentBatchId = null;
      }
    }

    return batches;
  }

  async backToPreviousRessinc(batchId, parentBatchId) {
    const batchRepository = new Repository(AVAILABLE_ENTITIES.BATCHES)
    const d0Repository = new Repository(AVAILABLE_ENTITIES.D0S)

    const currentIatf = this.state.currentBatch.iatfCount

    let batches = await this.fetchBatches(parentBatchId, batchRepository);
    batches.push(this.state.currentBatch)

    const allCowsToBatches = this.props.previousVacas.filter((item) => {
      return batches.some((batch) => item.loteId === batch.id);
    });

    try {
      const previousCowsGroupByIatf = allCowsToBatches.reduce(
        (groupByIatf, item) => {
          // Verificando se já existe uma chave para esse valor de iatf
          if (!groupByIatf[`iatf${item.iatf + 1}`]) {
            // Se não existir, criamos uma nova chave para esse valor de iatf
            groupByIatf[`iatf${item.iatf + 1}`] = []
          }
          // Adicionando o objeto ao array correspondente ao valor de iatf
          groupByIatf[`iatf${item.iatf + 1}`].push(item)

          return groupByIatf
        },
        {}
      )

      if (currentIatf === 2) {
        for (const cow of previousCowsGroupByIatf[`iatf${currentIatf - 1}`]) {
          cow.omit_from_dg_final = false

          await d0Repository.update(cow)
        }
      } else {
        const cowsWantToBeTrueOmmit = previousCowsGroupByIatf[
          `iatf${currentIatf - 2}`
        ]
          .filter((item) => {
            return (
              !item.omit_from_dg_final &&
              this.props.allBatchesIds[
                this.state.currentBatch.originalBatch.nomeLote
              ].includes(item.loteId)
            )
          })
          .map((item) => {
            return item.codVaca
          })

        for (const cow of previousCowsGroupByIatf[`iatf${currentIatf - 1}`]) {
          cow.omit_from_dg_final = cowsWantToBeTrueOmmit.includes(cow.codVaca)

          await d0Repository.update(cow)
        }
      }

      await batchRepository.delete(batchId, true)

      setTimeout(function () {
        window.location.reload()
      }, 1500)
    } catch (error) {
      console.log(error)

      this.props.endLoading()
      this.setState({ erroModalOpened: true })
    }
  }

  async SyncBatch(batches) {
    if (hasInternet()) {
      this.props.startLoading()
      await pullFarms([this.props.farm.id], {}, false, false, true, batches)
      this.props.endLoading()
    } else {
      this.setState({ noConnection: true })
    }

    if (hasInternet()) {
      setTimeout(function () {
        window.location.reload()
      }, 1500)
    }
  }

  @track((props, state, row) => ({
    action: 'Entrou no Retiro',
    value: row[0].id,
  }))
  handleEntryInCorral = (row) =>
    this.props.history.push(`/farms/${this.props.farm.id}/corrals/${row.id}`)

  @track((props, state, row) => ({
    action: 'Entrou no Incluir Matriz',
    value: row[0].id,
  }))
  handleEntryInPageD0AfterResync = (row) =>
    this.props.history.push(`/D0AfterResync/${row.originalBatchId}`)

  @track((props, state, row) => ({
    action: 'Entrou na D0/IATF',
    value: row[0].id,
  }))
  handleEntryInPageD0IATF = (row) =>
    this.props.history.push(`/D0IATF/${row.id}`)

  @track((props, state, row) => ({ action: 'Entrou na D0', value: row[0].id }))
  handleEntryInPageD0 = (row) => {
    currentDayIsD0(row)
      ? this.props.history.push(`/D0/${row.id}`)
      : this.setState({
        selectedRowId: row.id,
        d0OutOfDay: true,
      })
  }

  @track((props, state, row) => ({
    action: 'Entrou na D0/Ressinc',
    value: row[0].id,
  }))
  handleEntryInPageD0Ressinc = (row) =>
    this.props.history.push(`/D0Resync/${row.id}`)

  @track((props, state, row) => ({ action: 'Entrou na DN', value: row[0].id }))
  handleEntryInPageDN = (row) => this.props.history.push(`/DN/${row.id}`)

  @track((props, state, row) => ({
    action: 'Entrou na IATF',
    value: row[0].id,
  }))
  handleEntryInPageIATF = (row) => this.props.history.push(`/IATF/${row.id}`)

  @track((props, state, row) => ({
    action: 'Entrou na Ressinc',
    value: row[0].id,
  }))
  handleEntryInPageRessinc = (row) =>
    this.props.history.push(`/Resync/${row.id}`)

  @track((props, state, row) => ({
    action: 'Entrou na DG',
    value: row[0].id,
    manejo: row[1],
  }))
  handleEntryInPageDG = (row, manejo) =>
    this.props.history.push(`/DG/${row.id}`, {
      manejo,
      farm: {
        id: this.props.farm.id,
        label: formatName(this.props.farm.nome, FAZENDA)
      },
      retiro: {
        id: this.props.defaultCorral.id,
        label: formatName(this.props.defaultCorral.nome, RETIRO)
      }
    })

  handleEntryInPageDGRessinc = (row, manejo) => {
    this.props.history.push(`/DGRessinc/${row.id}`, {
      manejo,
      farm: {
        id: this.props.farm.id,
        label: formatName(this.props.farm.nome, FAZENDA)
      },
      retiro: {
        id: this.props.defaultCorral.id,
        label: formatName(this.props.defaultCorral.nome, RETIRO)
      }
    })
  }

  @track((props, state, row) => ({
    action: 'Entrou na DG Final',
    value: row[0].id,
  }))
  handleEntryInPageDGFinal = (row) =>
    this.props.history.push(`/DGFinal/${row.id}`)

  @track((props, state, row) => ({
    action: 'Clicou no botao de editar Retiro',
    value: row[0].id,
  }))
  handleEditCorral = (row) =>
    this.props.history.push(
      `/farms/${this.props.farm.id}/corrals/${row.id}/update`
    )

  @track((props, state, row) => ({
    action: 'Clicou no botao de editar Lote',
    value: row[0].id,
  }))
  handleEditBatch = (row) =>
    this.props.history.push(
      `/farms/${this.props.farm.id}/corrals/${row.retiroId}/batches/${row.id}/update`
    )

  @track((props, state, row) => ({
    action: 'Clicou no nome do lote, listagem de matrizes',
    value: row[0].id,
  }))
  handleEntryInBatch = (row) =>
    this.props.history.push(
      `/farms/${this.props.farm.id}/corrals/${row.retiroId}/batches/${row.originalBatch.id}`
    )

  getTableColumnsData() {
    return this.state.hasCorral
      ? [
        {
          name: 'Retiro',
          type: CELL_TYPES.LINK,
          label: (row) => row.nome,
          onClick: (row) => () => this.handleEntryInCorral(row),
        },
        {
          name: 'Matrizes',
          type: CELL_TYPES.TEXT,
          label: (row) =>
            this.state.cowCount.length > 0
              ? this.state.cowCount.filter((cc) => cc.corralId === row.id)[0]
                .countNumber
              : 0,
        },
        {
          type: CELL_TYPES.BUTTON_ARR,
          buttons: (row) =>
            _.compact([
              {
                icon: <EditIcon />,
                label: 'Editar',
                onClick: () => this.handleEditCorral(row),
              },
              {
                icon: <DeleteIcon />,
                label: 'Excluir',
                onClick: () => {
                  this.setState({
                    deleteCorralConfirmationOpened: true,
                    selectedCorral: row.id,
                    selectedCorralName: row.nome,
                  })
                },
                disabled:
                  this.props.user.roles[0].name ===
                  'Cliente (somente visualização)',
              },
            ]),
        },
      ]
      : [
        {
          name: 'Lote',
          type: CELL_TYPES.LINK,
          label: (row) => row.nomeLote,
          onClick: (row) => () => this.handleEntryInBatch(row),
        },
        {
          name: 'Protocolo',
          type: CELL_TYPES.TEXT,
          label: (row) => row.protocolName,
        },
        {
          name: 'IATF',
          type: CELL_TYPES.TEXT,
          label: (row) => row.iatfCount,
        },
        {
          name: 'Processo Atual',
          type: CELL_TYPES.TEXT,
          label: (row) =>
            row.pastDGFinal
              ? 'DG Final'
              : row.pastDG
                ? 'DG'
                : row.lastDoneDay
                  ? row.lastDoneDayHasIatf
                    ? 'IATF'
                    : row.lastDoneDay.valor
                  : '',
        },
        {
          name: 'Matrizes',
          type: CELL_TYPES.TEXT,
          label: (row) =>
            row.originalBatchId
              ? this.props.allVacas.filter((v) => v.loteId === row.id).length
              : this.state.vacas.filter((v) => v.loteId === row.id).length,
        },
        {
          type: CELL_TYPES.FLOAT_BUTTONS,
          buttons: (row) =>
            !row.originalBatch?.matrizBloqueada
              ? !row.reservedBy ||
                (row.reservedBy && row.reservedBy == this.props.user.id)
                ? _.compact([
                  //Incluir Matriz apos Ressinc
                  row.isResync &&
                  !row.pastDGFinal && {
                    label: 'Incluir Matriz',
                    onClick: () => this.handleEntryInPageD0AfterResync(row),
                  },
                  //D0/IATF
                  currentDayIsD0IATF(row) && {
                    label: `D0/IATF`,
                    onClick: () => this.handleEntryInPageD0IATF(row),
                  },
                  //D0
                  (currentDayIsD0(row) ||
                    currentDayIsDN(row) ||
                    currentDayIsIatf(row) ||
                    currentDayIsRessinc(row) ||
                    currentDayIsDGFinal(row)) &&
                  !row.isResync && {
                    label: 'D0',
                    onClick: () => this.handleEntryInPageD0(row),
                  },
                  //DG/D0
                  currentDayIsDGD0(row) &&
                  row.isResync &&
                  !row.isPrecoce && {
                    label: 'DG/D0',
                    onClick: () => this.handleEntryInPageDGRessinc(row, 'DG/D0'),
                  },
                  //D0Ressinc
                  (currentDayIsD0Ressinc(row) ||
                    currentDayIsDGDN(row) ||
                    currentDayIsDN(row) ||
                    currentDayIsIatf(row) ||
                    currentDayIsRessinc(row) ||
                    currentDayIsDGFinal(row)) &&
                  row.isResync &&
                  row.isPrecoce && {
                    label: 'D0',
                    onClick: () => this.handleEntryInPageD0Ressinc(row),
                  },
                  //DN
                  currentDayIsDN(row) && {
                    label: `${row.nextDay}`,
                    onClick: () => this.handleEntryInPageDN(row),
                  },
                  //IATF
                  (currentDayIsIatf(row) ||
                    currentDayIsRessinc(row) ||
                    currentDayIsDGFinal(row)) && {
                    label: 'IATF',
                    onClick: () => this.handleEntryInPageIATF(row),
                  },
                  //RESSINC
                  currentDayIsRessinc(row) && {
                    label: 'Ressinc',
                    disabled:
                      this.state.vacas.length > 0
                        ? this.checkBatchAccuracy(row)
                        : false,
                    onClick: () => this.handleEntryInPageRessinc(row),
                  },
                  //DGFinal
                  currentDayIsDG(row) && {
                    label: `DG Final`,
                    onClick: () => this.handleEntryInPageDGFinal(row),
                  },
                  //DG
                  (currentDayIsDG(row) || currentDayIsDGFinal(row)) && {
                    label: `DG`,
                    onClick: () => this.handleEntryInPageDG(row, 'DG'),
                  },
                  //DGFinal
                  (managementsOver(row) || currentDayIsDGFinal(row)) && {
                    label: `DG Final`,
                    onClick: () => this.handleEntryInPageDGFinal(row),
                  },
                  //DG/DN
                  currentDayIsDGDN(row) && {
                    label: `DG/${row.nextDay}`,
                    onClick: () => this.handleEntryInPageDGRessinc(row, `DG/${row.nextDay}`),
                  },
                  row.isResync &&
                  !row.pastDGFinal && {
                    icon: (
                      <SwapHorizontalCircleOutlinedIcon
                        style={{ fontSize: 23 }}
                      />
                    ),
                    label: 'Voltar para Ressinc anterior',
                    onClick: () => {
                      if (!hasInternet()) {
                        this.setState({ noConnection: true })
                        return
                      }

                      this.setState({
                        backToPreviousRessincConfirmationOpened: true,
                        selectedBatch: row.id,
                        currentBatch: row,
                        selectedBatchName: row.nomeLote,
                        selectedParentBatch: row.parentBatchId,
                      })
                    }
                  },
                  {
                    icon: <SyncIcon style={{ fontSize: 23 }} />,
                    label: 'Sincronizar',
                    onClick: () => this.SyncBatch([row.originalBatch.id]),
                  },
                  {
                    icon: <EditIcon style={{ fontSize: 23 }} />,
                    label: 'Editar',
                    onClick: () => this.handleEditBatch(row),
                  },
                  {
                    icon: <DeleteIcon style={{ fontSize: 23 }} />,
                    label: 'Excluir',
                    onClick: () => {
                      this.setState({
                        deleteConfirmationOpened: true,
                        selectedBatch: row.id,
                        selectedBatchName: row.nomeLote,
                        selectedParentBatch: row.parentBatchId,
                      })
                    },
                    disabled:
                      this.props.user.roles[0].name ===
                      'Cliente (somente visualização)',
                  },
                ])
                : _.compact([
                  {
                    label: `Lote Reservado`,
                    disabled: true,
                    onClick: () => this.handleEntryInPageD0IATF(row),
                  },
                  row.isResync &&
                  !row.pastDGFinal && {
                    icon: (
                      <SwapHorizontalCircleOutlinedIcon
                        style={{ fontSize: 23 }}
                      />
                    ),
                    label: 'Voltar para Ressinc anterior',
                    onClick: () => {
                      if (!hasInternet()) {
                        this.setState({ noConnection: true })
                        return
                      }

                      this.setState({
                        backToPreviousRessincConfirmationOpened: true,
                        selectedBatch: row.id,
                        currentBatch: row,
                        selectedBatchName: row.nomeLote,
                        selectedParentBatch: row.parentBatchId,
                      })
                    }
                  },
                  {
                    icon: <SyncIcon style={{ fontSize: 23 }} />,
                    label: 'Sincronizar',
                    onClick: () => this.SyncBatch([row.originalBatch.id]),
                  },
                  {
                    icon: <EditIcon style={{ fontSize: 23 }} />,
                    label: 'Editar',
                    onClick: () => this.handleEditBatch(row),
                  },
                  {
                    icon: <DeleteIcon style={{ fontSize: 23 }} />,
                    label: 'Excluir',
                    onClick: () => {
                      this.setState({
                        deleteConfirmationOpened: true,
                        selectedBatch: row.id,
                        selectedBatchName: row.nomeLote,
                        selectedParentBatch: row.parentBatchId,
                      })
                    },
                    disabled:
                      this.props.user.roles[0].name ===
                      'Cliente (somente visualização)',
                  },
                ])
              : [
                {
                  label: `Lote Bloqueado`,
                  onClick: () => this.setState({ blockingAdvice: row }),
                },
                row.isResync &&
                !row.pastDGFinal && {
                  icon: (
                    <SwapHorizontalCircleOutlinedIcon
                      style={{ fontSize: 23 }}
                    />
                  ),
                  label: 'Voltar para Ressinc anterior',
                  onClick: () => {
                    if (!hasInternet()) {
                      this.setState({ noConnection: true })
                      return
                    }

                    this.setState({
                      backToPreviousRessincConfirmationOpened: true,
                      selectedBatch: row.id,
                      currentBatch: row,
                      selectedBatchName: row.nomeLote,
                      selectedParentBatch: row.parentBatchId,
                    })
                  }
                },
                {
                  icon: <SyncIcon style={{ fontSize: 23 }} />,
                  label: 'Sincronizar',
                  onClick: () => this.SyncBatch([row.originalBatch.id]),
                },
                {
                  icon: <EditIcon style={{ fontSize: 23 }} />,
                  label: 'Editar',
                  onClick: () => this.handleEditBatch(row),
                },
                {
                  icon: <DeleteIcon style={{ fontSize: 23 }} />,
                  label: 'Excluir',
                  onClick: () => {
                    this.setState({
                      deleteConfirmationOpened: true,
                      selectedBatch: row.id,
                      selectedBatchName: row.nomeLote,
                      selectedParentBatch: row.parentBatchId,
                    })
                  },
                  disabled:
                    this.props.user.roles[0].name ===
                    'Cliente (somente visualização)',
                },
              ],
        },
      ]
  }

  getMobileData() {
    if (this.state.hasCorral) {
      return {
        title: 'Retiro',
        values: this.state.selectedData.map((item) => ({
          '': '',
          Retiro: item.nome,
          Matrizes:
            this.state.cowCount.length > 0
              ? this.state.cowCount.filter((cc) => cc.corralId === item.id)[0]
                .countNumber
              : 0,
          Actions: _.compact([
            {
              icon: <CallMissedOutgoingRoundedIcon style={{ fontSize: 23 }} />,
              label: 'Entrar na Retiro',
              onClick: () => this.handleEntryInCorral(item),
            },
            {
              icon: <EditIcon />,
              label: 'Editar',
              onClick: () => this.handleEditCorral(item),
            },
            {
              icon: <DeleteIcon />,
              label: 'Excluir',
              onClick: () => {
                this.setState({
                  deleteCorralConfirmationOpened: true,
                  selectedCorral: item.id,
                  selectedCorralName: item.nome,
                })
              },
              disabled:
                this.props.user.roles[0].name ===
                'Cliente (somente visualização)',
            },
          ]),
        })),
        noDataMessage: `Ainda não existe nenhum ${this.state.hasCorral ? 'retiro' : 'lote'
          } nesta fazenda`,
      }
    } else {
      return {
        title: 'Lote',
        values: this.state.selectedData.map((item, index) => ({
          Lote: item.nomeLote,
          Protocolo: item.protocolName,
          'Data da IATF': item.dataToIATF,
          IATF: item.iatfCount,
          '% Prenhez': `${this.getPrenhez()[index]}%`,
          'Processo Atual': item.pastDGFinal
            ? 'DG Final'
            : item.pastDG
              ? 'DG'
              : item.lastDoneDay
                ? item.lastDoneDayHasIatf
                  ? 'IATF'
                  : item.lastDoneDay.valor
                : '-',
          Matrizes: item.originalBatchId
            ? this.props.allVacas.filter((v) => v.loteId === item.id).length
            : this.state.vacas.filter((v) => v.loteId === item.id).length,
          Actions: !item.originalBatch?.matrizBloqueada
            ? !item.reservedBy ||
              (item.reservedBy && item.reservedBy == this.props.user.id)
              ? _.compact([
                item.isResync &&
                !item.pastDGFinal && {
                  label: 'Incluir Matriz',
                  onClick: () => this.handleEntryInPageD0AfterResync(item),
                },
                currentDayIsD0IATF(item) && {
                  label: `D0/IATF`,
                  onClick: () => this.handleEntryInPageD0IATF(item),
                },
                //D0
                (currentDayIsD0(item) ||
                  currentDayIsDN(item) ||
                  currentDayIsIatf(item) ||
                  currentDayIsRessinc(item) ||
                  currentDayIsDGFinal(item)) &&
                !item.isResync && {
                  label: 'D0',
                  onClick: () => this.handleEntryInPageD0(item),
                },
                //DG/D0
                currentDayIsDGD0(item) &&
                item.isResync &&
                !item.isPrecoce && {
                  label: 'DG/D0',
                  onClick: () => this.handleEntryInPageDGRessinc(item, 'DG/D0'),
                },
                //D0Ressinc
                (currentDayIsD0Ressinc(item) ||
                  currentDayIsDGDN(item) ||
                  currentDayIsDN(item) ||
                  currentDayIsIatf(item) ||
                  currentDayIsRessinc(item) ||
                  currentDayIsDGFinal(item)) &&
                item.isResync &&
                item.isPrecoce && {
                  label: 'D0',
                  onClick: () => this.handleEntryInPageD0Ressinc(item),
                },
                //DN
                currentDayIsDN(item) && {
                  label: `${item.nextDay}`,
                  onClick: () => this.handleEntryInPageDN(item),
                },
                //IATF
                (currentDayIsIatf(item) ||
                  currentDayIsRessinc(item) ||
                  currentDayIsDGFinal(item)) && {
                  label: 'IATF',
                  onClick: () => this.handleEntryInPageIATF(item),
                },
                //RESSINC
                currentDayIsRessinc(item) && {
                  label: 'Ressinc',
                  disabled:
                    this.state.vacas.length > 0
                      ? this.checkBatchAccuracy(item)
                      : false,
                  onClick: () => this.handleEntryInPageRessinc(item),
                },
                //DGFinal
                currentDayIsDG(item) && {
                  label: `DG Final`,
                  onClick: () => this.handleEntryInPageDGFinal(item),
                },
                //DG
                (currentDayIsDG(item) || currentDayIsDGFinal(item)) && {
                  label: `DG`,
                  onClick: () => this.handleEntryInPageDG(item, 'DG'),
                },
                //DGFinal
                (managementsOver(item) || currentDayIsDGFinal(item)) && {
                  label: `DG Final`,
                  onClick: () => this.handleEntryInPageDGFinal(item),
                },
                //DG/DN
                currentDayIsDGDN(item) && {
                  label: `DG/${item.nextDay}`,
                  onClick: () => this.handleEntryInPageDGRessinc(item, `DG/${item.nextDay}`),
                },
                {
                  icon: (
                    <CallMissedOutgoingRoundedIcon style={{ fontSize: 23 }} />
                  ),
                  label: 'Entrar na Lote',
                  onClick: () => this.handleEntryInBatch(item),
                },
                item.isResync &&
                !item.pastDGFinal && {
                  icon: (
                    <SwapHorizontalCircleOutlinedIcon
                      style={{ fontSize: 23 }}
                    />
                  ),
                  label: 'Voltar para Ressinc anterior',
                  onClick: () => {
                    if (!hasInternet()) {
                      this.setState({ noConnection: true })
                      return
                    }

                    this.setState({
                      backToPreviousRessincConfirmationOpened: true,
                      selectedBatch: item.id,
                      currentBatch: item,
                      selectedBatchName: item.nomeLote,
                      selectedParentBatch: item.parentBatchId,
                    })
                  }
                },
                {
                  icon: <SyncIcon style={{ fontSize: 23 }} />,
                  label: 'Sincronizar',
                  onClick: () => this.SyncBatch([item.originalBatch.id]),
                },
                {
                  icon: <EditIcon style={{ fontSize: 23 }} />,
                  label: 'Editar',
                  onClick: () => this.handleEditBatch(item),
                },
                {
                  icon: <DeleteIcon style={{ fontSize: 23 }} />,
                  label: 'Excluir',
                  onClick: () => {
                    this.setState({
                      deleteConfirmationOpened: true,
                      selectedBatch: item.id,
                      selectedBatchName: item.nomeLote,
                      selectedParentBatch: item.parentBatchId,
                    })
                  },
                  disabled:
                    this.props.user.roles[0].name ===
                    'Cliente (somente visualização)',
                },
              ])
              : _.compact([
                {
                  icon: (
                    <CallMissedOutgoingRoundedIcon style={{ fontSize: 23 }} />
                  ),
                  label: 'Entrar na Lote',
                  onClick: () => this.handleEntryInBatch(item),
                },
                {
                  label: `Lote Reservado`,
                  disabled: true,
                  onClick: () => this.handleEntryInPageD0IATF(item),
                },
                item.isResync &&
                !item.pastDGFinal && {
                  icon: (
                    <SwapHorizontalCircleOutlinedIcon
                      style={{ fontSize: 23 }}
                    />
                  ),
                  label: 'Voltar para Ressinc anterior',
                  onClick: () => {
                    if (!hasInternet()) {
                      this.setState({ noConnection: true })
                      return
                    }

                    this.setState({
                      backToPreviousRessincConfirmationOpened: true,
                      selectedBatch: item.id,
                      currentBatch: item,
                      selectedBatchName: item.nomeLote,
                      selectedParentBatch: item.parentBatchId,
                    })
                  }
                },
                {
                  icon: <SyncIcon style={{ fontSize: 23 }} />,
                  label: 'Sincronizar',
                  onClick: () => this.SyncBatch([item.originalBatch.id]),
                },
                {
                  icon: <EditIcon style={{ fontSize: 23 }} />,
                  label: 'Editar',
                  onClick: () => this.handleEditBatch(item),
                },
                {
                  icon: <DeleteIcon style={{ fontSize: 23 }} />,
                  label: 'Excluir',
                  onClick: () => {
                    this.setState({
                      deleteConfirmationOpened: true,
                      selectedBatch: item.id,
                      selectedBatchName: item.nomeLote,
                      selectedParentBatch: item.parentBatchId,
                    })
                  },
                  disabled:
                    this.props.user.roles[0].name ===
                    'Cliente (somente visualização)',
                },
              ])
            : [
              {
                label: `Lote Bloqueado`,
                onClick: () => this.setState({ blockingAdvice: item }),
              },
              item.isResync &&
              !item.pastDGFinal && {
                icon: (
                  <SwapHorizontalCircleOutlinedIcon
                    style={{ fontSize: 23 }}
                  />
                ),
                label: 'Voltar para Ressinc anterior',
                onClick: () => {
                  if (!hasInternet()) {
                    this.setState({ noConnection: true })
                    return
                  }

                  this.setState({
                    backToPreviousRessincConfirmationOpened: true,
                    selectedBatch: item.id,
                    currentBatch: item,
                    selectedBatchName: item.nomeLote,
                    selectedParentBatch: item.parentBatchId,
                  })
                }
              },
              {
                icon: <SyncIcon style={{ fontSize: 23 }} />,
                label: 'Sincronizar',
                onClick: () => this.SyncBatch([item.originalBatch.id]),
              },
              {
                icon: <EditIcon style={{ fontSize: 23 }} />,
                label: 'Editar',
                onClick: () => this.handleEditBatch(item),
              },
              {
                icon: <DeleteIcon style={{ fontSize: 23 }} />,
                label: 'Excluir',
                onClick: () => {
                  this.setState({
                    deleteConfirmationOpened: true,
                    selectedBatch: item.id,
                    selectedBatchName: item.nomeLote,
                    selectedParentBatch: item.parentBatchId,
                  })
                },
                disabled:
                  this.props.user.roles[0].name ===
                  'Cliente (somente visualização)',
              },
            ],
        })),
        noDataMessage: `Ainda não existe nenhum ${this.state.hasCorral ? 'retiro' : 'lote'
          } nesta fazenda`,
      }
    }
  }

  @track((props, state) => ({
    action: `Clicou no botao de novo ${state.hasCorral ? 'retiro' : 'lote'}`,
  }))
  handleCreateNewCorral = () => {
    this.props.history.push(
      this.state.hasCorral
        ? `/farms/${this.props.match.params.id}/corrals/create`
        : `/farms/${this.props.match.params.id}/corrals/${this.props.defaultCorral && this.props.defaultCorral.id
        }/batches/create`
    )
  }

  @track({ action: 'Clicou no botao de mesclar' })
  handleMergeCorral = () => {
    this.props.history.push(
      `/farms/${this.props.match.params.id}/corrals/${this.props.defaultCorral.id}/mergeBatches`
    )
  }

  @track({ action: 'Clicou no botao de exportar' })
  handleExport = (selectedFieldsOptionsExport, isPowerBI = false) => {
    if (hasInternet()) {
      getFarmFile(
        this.props.farm.id,
        selectedFieldsOptionsExport,
        isPowerBI
          ? `${formatName(this.props.farm.nome, FAZENDA)}-powerbi`
          : formatName(this.props.farm.nome, FAZENDA)
      )
    } else {
      this.setState({ noConnection: true })
    }
  }

  @track({ action: 'Clicou no botao de editar' })
  handleEdit = () =>
    this.props.history.push(`/farms/${this.props.farm.id}/update`)

  render() {
    const mergeOption = !this.state.hasCorral && {
      onClick: this.handleMergeCorral,
      label: 'Mesclar lotes',
    }

    return (
      <Container>
        <TopBar title={formatName(this.props.farm.nome, FAZENDA)} />
        <TitleBar
          title={formatName(this.props.farm.nome, FAZENDA)}
          buttons={_.compact([
            {
              onClick: this.handleCreateNewCorral,
              icon: <AddIcon />,
              label: this.state.hasCorral ? 'Retiro' : 'Lote',
            },
            mergeOption,
            {
              onClick: () => this.verifyConnection(),
              label: 'Relatório da Fazenda',
            },
            {
              hasDropdown: true,
              buttons: [
                {
                  onClick: () => this.handleExport([]),
                  label: 'Exportar Fazenda',
                },
                {
                  onClick: () => this.setState({ showFields: true }),
                  label: 'Exportação Personalizada',
                },
                {
                  onClick: () =>
                    this.handleExport(POWER_BI_FIELDS_TO_EXPORT, true),
                  label: 'Exportação para o POWER BI',
                },
              ],
              label: 'Exportação',
            },
            {
              onClick: this.handleEdit,
              label: 'Editar',
            },
          ])}
          paths={[
            {
              label: formatName(this.props.farm.nome, FAZENDA),
            },
          ]}
        />
        {!this.state.hasCorral && (
          <TrainingsButton
            page={`/farms/${this.props.match.params.id}`}
            hasCorral={this.state.hasCorral}
          />
        )}
        <MainContainer
          containerTitle={`${this.state.hasCorral ? 'Retiros' : 'Lotes'}`}
          hasSearch
          handleSearch={this.handleSearch}
        >
          {window.innerWidth > 767 ? (
            <ResponsiveTable
              columns={this.getTableColumnsData()}
              className='table-farm'
              noDataMessage={`Ainda não existe nenhum ${this.state.hasCorral ? 'retiro' : 'lote'
                } nesta fazenda`}
              data={this.state.selectedData}
              toDataExport={this.props.data}
            />
          ) : (
            <MobileCard data={this.getMobileData()} />
          )}

          <Prompt
            visible={verify()}
            title='Página duplicada!'
            message={`Para evitar uma possível perda de dados, não é permitido abrir o Progerar em mais de uma aba! 
            Para continuar utilizando, feche esta página e volte para utilizar a anterior.`}
            buttons={[
              {
                autoFocus: false,
              },
            ]}
          />

          <Prompt
            visible={this.state.showFields}
            title='Escolha os campos que deseja visualizar na exportação'
            className='hidewhenprinting'
            message={
              <div className='grid-item p-12 d-3 hidewhenprinting'>
                <Autocomplete
                  multiple
                  id='allFieldsOptionsExport'
                  defaultValue={
                    JSON.parse(
                      LocalStorageHelper.get('ProGerarSavedFieldsToExport')
                    ) ?? this.state.allFieldsOptionsExport
                  }
                  onChange={(_, value) => {
                    this.setState({
                      selectedFieldsOptionsExport: value.map((item) => item),
                    })
                    LocalStorageHelper.add(
                      'ProGerarSavedFieldsToExport',
                      JSON.stringify(value)
                    )
                  }}
                  filterSelectedOptions
                  options={this.state.allFieldsOptionsExport}
                  getOptionLabel={(option) => option}
                  style={{ width: '100%', backgroundColor: 'white' }}
                  renderInput={(params) => (
                    <TextField {...params} variant='outlined' />
                  )}
                />
              </div>
            }
            leftButton={{
              label: 'Redefinir',
              onClick: () => {
                this.setState({
                  selectedFieldsOptionsExport: [],
                  showFields: false,
                })
                LocalStorageHelper.add(
                  'ProGerarSavedFieldsToExport',
                  JSON.stringify(ALL_FIELDS_TO_EXPORT)
                )
              }
            }}
            buttons={[
              {
                label: 'Fechar',
                onClick: () => this.setState({ showFields: false }),
              },
              {
                label: 'Exportar',
                onClick: () => {
                  this.handleExport(this.state.selectedFieldsOptionsExport)
                  this.setState({ showFields: false })
                },
              },
            ]}
          />

          <Prompt
            visible={this.state.deleteConfirmationOpened}
            title='Confirmar Exclusão'
            message={`Deseja realmente excluir o lote (${this.state.selectedBatchName})?`}
            buttons={[
              {
                label: 'Não',
                onClick: () =>
                  this.setState({ deleteConfirmationOpened: false }),
              },
              {
                label: 'Sim',
                onClick: () => this.deleteBatch(this.state.selectedBatch),
              },
            ]}
          />

          <Prompt
            visible={this.state.d0OutOfDay}
            title='D0 já finalizado.'
            message={`Este lote já possui a etapa de D0 finalizada. Caso deseje adicionar novas matrizes, lembre-se de preencher os demais campos nas outras etapas de manejo.`}
            buttons={[
              {
                label: 'Cancelar',
                onClick: () =>
                  this.setState({ d0OutOfDay: false, selectedRowId: null }),
              },
              {
                label: 'Continuar',
                onClick: () =>
                  this.props.history.push(`/D0/${this.state.selectedRowId}`),
              },
            ]}
          />

          <Prompt
            visible={this.state.deleteCorralConfirmationOpened}
            title='Confirmar Exclusão'
            message={`Deseja realmente excluir o retiro (${this.state.selectedCorralName})?`}
            buttons={[
              {
                label: 'Não',
                onClick: () =>
                  this.setState({ deleteCorralConfirmationOpened: false }),
              },
              {
                label: 'Sim',
                onClick: () => this.deleteCorral(this.state.selectedCorral),
              },
            ]}
          />

          <Prompt
            visible={this.state.blockingAdvice != null}
            title='Lote bloqueado!'
            message={
              this.state.blockingAdvice &&
              `Este lote se encontra bloqueado devido ao cadastro incompleto da Matriz: ${this.state.vacas.filter(
                (v) =>
                  v.id ==
                  this.state.blockingAdvice.originalBatch?.matrizBloqueada
              )[0].codVaca
              }, para que o lote seja desbloqueado, a matriz em questão deve ser excluida na tela do lote.`
            }
            buttons={[
              {
                label: 'Cancelar',
                onClick: () => this.setState({ blockingAdvice: null }),
              },
              {
                label: 'Ir para o Lote',
                onClick: () =>
                  this.props.history.push(
                    `/farms/${this.props.farm.id}/corrals/${this.state.blockingAdvice.retiroId}/batches/${this.state.blockingAdvice.originalBatch.id}`
                  ),
              },
            ]}
          />

          <Prompt
            visible={this.state.noConnection}
            title='Não é possível realizar esta ação!'
            message={`Você está sem internet no momento. Verifique sua conexão e tente novamente mais tarde.`}
            buttons={[
              {
                label: 'Ok',
                onClick: () => this.setState({ noConnection: false }),
              },
            ]}
          />

          <Prompt
            visible={this.state.backToPreviousRessincConfirmationOpened}
            title='Confirmação de retorno para Ressinc Anterior'
            message={
              <div>
                <p>
                  Você tem certeza de que deseja retornar à Ressinc do lote:{' '}
                  <span
                    style={{ textTransform: 'uppercase', fontWeight: 'bold' }}
                  >
                    {this.state.selectedBatchName}
                  </span>
                  ?{' '}
                  <span style={{ fontWeight: 'bold' }}>
                    (Ao fazer isso seu lote irá voltar para a IATF{' '}
                    {this.state.currentBatch?.iatfCount - 1})
                  </span>
                </p>
                <br />
              </div>
            }
            buttons={[
              {
                label: 'Não',
                onClick: () =>
                  this.setState({
                    backToPreviousRessincConfirmationOpened: false,
                  }),
              },
              {
                label: 'Sim',
                onClick: () => {
                  this.props.startLoading()

                  this.setState({
                    backToPreviousRessincConfirmationOpened: false,
                  })
                  this.backToPreviousRessinc(
                    this.state.selectedBatch,
                    this.state.selectedParentBatch
                  )
                },
              },
            ]}
          />
          <Prompt
            visible={this.state.erroModalOpened}
            title='Algo deu errado!'
            message='Tente novamente mais tarde.'
            buttons={[
              {
                label: 'Ok',
                onClick: () =>
                  this.setState({
                    erroModalOpened: false,
                  }),
              },
            ]}
          />
        </MainContainer>
      </Container>
    )
  }
}

const propsFromDatabase = async (props) => {
  const farm = await utils.getElement(
    AVAILABLE_ENTITIES.FARMS,
    props.match.params.id
  )

  const corrals = await utils.getWithParam(
    AVAILABLE_ENTITIES.CORRALS,
    'farm_id',
    farm.id
  )

  const allBatches = await utils.getWithParam(
    AVAILABLE_ENTITIES.BATCHES,
    'corral_id',
    corrals[0]?.id
  )
  const batchList = await batches.getBatchesByFarm(farm)
  const condicaoDG = _.sortBy(await utils.getDomainValuesBy('Condição DG'), [
    (condicao) => condicao.valor !== 'Prenhe',
    (condicao) => condicao.valor !== 'Vazia',
    (condicao) => condicao.valor !== 'Descarte Prenhe',
    (condicao) => condicao.valor !== 'Descarte Vazia',
  ])
  const allVacas = await utils.getWithParam(
    AVAILABLE_ENTITIES.D0S,
    'batch_id',
    Q.oneOf(batchList.map((batch) => batch.id))
  )

  const previousVacas = await utils.getWithParam(
    AVAILABLE_ENTITIES.D0S,
    'batch_id',
    Q.oneOf(allBatches.map((batch) => batch.id))
  )

  const allBatchesIds = allBatches.reduce((groupByBatch, item) => {
    let batchName = item.nomeLote
    if (batchName.includes('- IATF')) {
      batchName = batchName.split(' - IATF')[0]
    }

    groupByBatch[batchName] = groupByBatch[batchName] || []
    groupByBatch[batchName].push(item.id)

    return groupByBatch
  }, {})

  const defaultCorral = (
    await utils.getWithParam(AVAILABLE_ENTITIES.CORRALS, 'farm_id', farm.id)
  )[0]
  const data = farm.retiro
    ? await utils.getWithParam(AVAILABLE_ENTITIES.CORRALS, 'farm_id', farm.id)
    : await batches.getBatchesByFarm(farm)

  return {
    farm,
    data,
    allVacas,
    previousVacas,
    allBatchesIds,
    batches: batchList,
    condicaoDG,
    defaultCorral,
    user: await utils.getElement(
      AVAILABLE_ENTITIES.USERS,
      (
        await getLoggedUser()
      ).userId
    ),
  }
}

const mapDispatchToProps = (dispatch) => ({
  startLoading: () => dispatch(startLoading()),
  endLoading: () => dispatch(endLoading()),
})

export default connect(
  null,
  mapDispatchToProps
)(utils.withPropsFromDatabase(propsFromDatabase, withRouter(Farm)))

// export default utils.withPropsFromDatabase(propsFromDatabase, withRouter(Farm))
